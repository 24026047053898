export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/center/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/center/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/center/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/user-account',
    name: 'user-account',
    component: () => import('@/views/center/user-account/UserAccount.vue'),
    meta: {
      pageTitle: 'User Account',
      breadcrumb: [
        {
          text: 'User Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/center/user/user-list/UserList.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/center/user/user-edit/UserEdit.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'User List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/user/view/:id',
    name: 'user-view',
    component: () => import('@/views/center/user/user-view/UserView.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/user/add',
    name: 'user-add',
    component: () => import('@/views/center/user/user-add/UserAdd.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
]
