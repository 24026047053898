export default [
  // e-coupon/coupon
  {
    path: '/e-coupon/coupon/list',
    name: 'ecoupon-coupon-list',
    component: () => import('@/views/e-coupon/coupon/coupon-list/CouponList.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'coupon-ecoupon',
    },
  },
  {
    path: '/coupon/edit/:id',
    name: 'ecoupon-coupon-edit',
    component: () => import('@/views/e-coupon/coupon/coupon-edit/CouponEdit.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'Coupon List',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      navActiveLink: 'ecoupon-coupon-list',
    },
  },
  {
    path: '/e-coupon/coupon/add',
    name: 'ecoupon-coupon-add',
    component: () => import('@/views/e-coupon/coupon/coupon-add/CouponAdd.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'List',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      navActiveLink: 'ecoupon-coupon-list',
    },
  },
  {
    path: '/e-coupon/coupon/view/:id',
    name: 'ecoupon-coupon-view',
    component: () => import('@/views/e-coupon/coupon/coupon-view/CouponView.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'List',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'ecoupon-coupon-list',
    },
  },
  {
    path: '/e-coupon/coupon/import',
    name: 'ecoupon-coupon-import',
    component: () => import('@/views/e-coupon/coupon/coupon-import/CouponImport.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'List',
          to: '/e-coupon/coupon/list',
          active: false,
        },
        {
          text: 'Import',
          active: true,
        },
      ],
      navActiveLink: 'ecoupon-coupon-list',
    },
  },
  // e-coupon/redeem
  {
    path: '/e-coupon/redeem/list',
    name: 'redeem-list',
    component: () => import('@/views/e-coupon/redeem/redeem-list/RedeemList.vue'),
    meta: {
      pageTitle: 'Redeem',
      breadcrumb: [
        {
          text: 'Redeem',
          to: '/e-coupon/redeem/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'redeem-ecoupon',
    },
  },
  // e-coupon/refund
  {
    path: '/e-coupon/refund/list',
    name: 'refund-list',
    component: () => import('@/views/e-coupon/refund/refund-list/RefundList.vue'),
    meta: {
      pageTitle: 'Refund',
      breadcrumb: [
        {
          text: 'Refund',
          to: '/e-coupon/refund/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'refund-ecoupon',
    },
  },
  // e-coupon/brand
  {
    path: '/e-coupon/brand/list',
    name: 'ecoupon-brand-list',
    component: () => import('@/views/e-coupon/brand/BrandList.vue'),
    meta: {
      pageTitle: 'Brand',
      breadcrumb: [
        {
          text: 'Brand',
          to: '/e-coupon/brand/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'brand-ecoupon',
    },
  },
  // e-coupon/merchant
  {
    path: '/e-coupon/merchant/list',
    name: 'ecoupon-merchant-list',
    component: () => import('@/views/e-coupon/merchant/MerchantList.vue'),
    meta: {
      pageTitle: 'Merchant',
      breadcrumb: [
        {
          text: 'Merchant',
          to: '/e-coupon/merchant/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'merchant-ecoupon',
    },
  },
]
